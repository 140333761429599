interface IJoinUrlSegmentsOptions {
    /**
     * Dictates whether a trailing slash should be appended to the final segment
     * of the generated URL (if not already present)
     *
     * @default true
     */

    addTrailingSlash?: boolean;
}

/**
 * Given a list of one or more URL segments (possibly containing one or more absolute segments starting
 * with a protocol) ensures that a final valid URL is produced where relative paths are concatenated,
 * and in the case of multiple absolute paths, that the last occurrence overrides all other
 * occurrences.
 */

const joinUrlSegments = (segments: string[], {addTrailingSlash = true}: IJoinUrlSegmentsOptions = {}): string => {
    let url: string = '';

    for (let i = 0; i < segments.length; i++) {
        const shouldAddTrailingSlashToSegment = i < segments.length - 1 || addTrailingSlash;
        const nextSegment = segments[i + 1] ?? '';

        let segment = segments[i];

        // Ignore empty segments

        if (segment === '') continue;

        // Force trailing slash

        if (!segment.match(/\/$/) && shouldAddTrailingSlashToSegment && !nextSegment.match(/^\//)) segment += '/';

        if (segment.match(/^http/)) {
            // Absolute segments override any previous segments (absolute or relative)

            url = segment;
        } else {
            // Relative segments are concatenated on

            url += segment;
        }
    }

    return url;
};

export {joinUrlSegments};
export type {IJoinUrlSegmentsOptions};
