class Quality {
    /**
     * The number of video frames that have been dropped since the last time the media in the `<video>`
     * element was loaded or reloaded.
     */
    public droppedVideoFrames: number = 0;

    /**
     * The change in the number of dropped video frames per second between time updates.
     */
    public droppedVideoFramesAcceleration: number = 0;

    /**
     * The number of dropped video frames per second between time updates.
     */
    public droppedVideoFramesSpeed: number = 0;

    /**
     * Last time an instance has been updated.
     */
    public lastUpdateWallclockTimeMs: number = Date.now();

    /**
     * The total number of video frames the `<video>` element has displayed or dropped since
     * the media was loaded or reloaded.
     */
    public totalVideoFrames: number = 0;

    /**
     * The percentage of the total video frames that have been dropped since the media was
     * loaded or reloaded.
     */
    public videoFrameFailureRate: number = 0;
}

export {Quality};
