/**
 * A simple implementation of `Object.values()` for
 * es5 runtime support.
 *
 * @param obj An object from which to extract values
 */

// Interfaces are assignable to `Record<string, any>` but not `Record<string, unknown>`

const getObjectValues = <TObject extends Record<string, any>>(obj: TObject): Array<TObject[keyof TObject]> => {
    const values: Array<TObject[keyof TObject]> = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            values.push(obj[key]);
        }
    }

    return values;
};

export {getObjectValues};
