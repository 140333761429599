import {SegmentStatus} from '@Shared/Constants/SegmentStatus';

import type {BufferData} from '../Models/BufferData';

/**
 * Drop BUFFERING ranges - this includes segments currently DOWNLOADING and DOWNLOADED, AWAITING APPEND.
 * We always drop segments awaiting append as we cannot guarantee they are in the correct order
 * By default this method ensures any segments already in APPENDING phase are not accidentally deleted.
 */

const dropAllBufferingRanges = (bufferData: BufferData, abortAppendingSegments?: boolean): void => {
    for (const urlKey in bufferData.bufferingRangeMap) {
        if (!bufferData.bufferingRangeMap.hasOwnProperty(urlKey)) continue;

        const segment = bufferData.bufferingRangeMap[urlKey];

        if (segment.status !== SegmentStatus.APPENDING || abortAppendingSegments) {
            delete bufferData.bufferingRangeMap[urlKey];
        }
    }

    bufferData.previousBufferingSegment = null;
};

export {dropAllBufferingRanges};
