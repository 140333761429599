import {DebugVerbosityLevel} from '@Logger/Constants/DebugVerbosityLevel';
import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';

class LocalStorage {
    /**
     * A boolean indicating whether debug logging to console is enabled.
     */

    public debug = false;

    /**
     * A numeric enum value, representing the level of filtering to apply
     * to logger messages when the `DebugView` is attached.
     */

    public verbosityLevel: DebugVerbosityLevel = DebugVerbosityLevel.LOG;

    /**
     * An array of strings to filter logs against.
     */

    public loggingFilters: string[] = [];

    /**
     * A dynamically generated map of unique colours to log types
     * and log namespaces.
     */

    public loggingColours: Partial<Record<MessageNamespace, string>> = {};

    /**
     * The last recorded value of `metrics.network.timeBasedBandwidthEstimate`, to
     * be saved across sessions on a device. This allows for start up at an ideal
     * bitrate, rather than "discovering" the ideal bitrate via the downloading of
     * a sub-optimal bitrate up front.
     */

    public mostRecentBandwidthEstimate: number = -1;
}

export {LocalStorage};
