import {Network} from './Network';
import {Playback} from './Playback';
import {Timing} from './Timing';

class Metrics {
    /**
     * All timing and buffer related state
     */

    public timing: Timing = new Timing();

    /**
     * All network metrics related state
     */

    public network: Network = new Network();

    /**
     * All general playback related state
     */

    public playback: Playback = new Playback();

    /**
     * A flag indicating whether hungry buffer mode is active, given the current
     * buffer and network state
     */

    public get isHungryBufferActive(): boolean {
        return this.timing.isBufferHealthy && this.network.bandwidthRedundancyFactor > 1;
    }

    /**
     * A flag indicating that a seek is in progress, and the time to seek to is outside
     * of any previously buffered ranges
     */

    public get isSeekingOutsideBufferedRange(): boolean {
        return this.playback.isSeeking && !this.timing.isInsideBufferOrGap;
    }
}

export {Metrics};
