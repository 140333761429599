// NB: 1000 is intentionally chosen over 1024 as we are measuring Mb not Mib, Kb not Kib.

const THOUSAND = 1000;

/**
 * Adds a standard-unit suffix and rounds as needed.
 */

const getHumanReadableBitrate = (bitrate: number): string => {
    const kbps = Math.round(bitrate / THOUSAND);

    if (kbps >= THOUSAND) {
        return kbps / THOUSAND + 'Mbps';
    }

    return kbps + 'Kbps';
};

export {getHumanReadableBitrate};
