/**
 * Creates a EWMA computation function
 *
 * @param influence The amount of influence to apply to next and previous values
 */
const createEwmaComputation =
    (influence: number = 0.1) =>
    (next: number, previous?: number): number => {
        if (typeof previous === 'undefined') return next;

        return next * influence + (1 - influence) * previous;
    };

export {createEwmaComputation};
