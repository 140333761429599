import type {Segment} from '@Shared/Models/Segment';
import {hasElements} from '@Shared/Util/hasElements';

import type {IRange} from '../Interfaces/IRange';

import {getTimeSortedBufferRanges} from './getTimeSortedBufferRanges';

const createRange = (): IRange => ({
    startSeconds: -1,
    endSeconds: -1,
});

const getAllRanges = (rangeMap: Record<number, Segment>): IRange[] => {
    const ranges: IRange[] = [];
    const sortedBufferRanges = getTimeSortedBufferRanges(rangeMap);

    if (!hasElements(sortedBufferRanges)) return ranges;

    let currentRange: IRange = createRange();

    for (let i = 0; i < sortedBufferRanges.length; i++) {
        const segment = sortedBufferRanges[i];
        const nextSegment = sortedBufferRanges[i + 1] ?? null;

        if (currentRange.startSeconds < 0) {
            currentRange.startSeconds = segment.startTimeSeconds;
        }

        if (!nextSegment || nextSegment.startTimeSeconds > segment.endTimeSeconds) {
            currentRange.endSeconds = segment.endTimeSeconds;

            ranges.push(currentRange);

            currentRange = createRange();
        }
    }

    return ranges;
};

export {getAllRanges};
