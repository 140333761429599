/* eslint-disable max-len */
/**
 * All configuration related to Mercury behaviour.
 *
 * @configurable
 * @title Behaviour
 *
 * @remark
 * The majority of the Behaviour configurations can and should be configured per platform.
 * For the most part this defines the capabilities and behaviours of different MSE/EME
 * implementations, allowing Mercury to behave consistently across these.
 */

import {MessageType} from '@Logger/Constants/MessageType';

class ConfigBehaviour {
    /**
     * A boolean dictating whether playback should start automatically once
     * buffer data is available.
     */

    public autoplay = true;

    /**
     * Describes how the SourceBufferManager should append segments to the underlying SourceBuffers
     * - BALANCED: Aim to keep the audio and video buffers balanced in size.
     * - UNBALANCED: Append segments to either buffer as soon as they are available.
     */
    public appendStrategy: 'UNBALANCED' | 'BALANCED' = 'BALANCED';

    /**
     * A boolean dictating whether the MSE `MediaSource` (an abstraction over the
     * underlying native "media pipeline") should be destroyed and rebuilt on
     * every period change. This is necessary on some living room targets to flush
     * the decoder.
     *
     * @configurable
     * @title replaceMediaSourceOnPeriodChange (multi-period only)
     *
     * @remark
     * On Tizen 2.x, when playing multi-period DASH content (e.g. for DAI purposes) significant
     * decoder issues are observed upon entry to the next period in a manifest after the first
     * played period at start up, where the device begins to drop frames and playback is reduced
     * to < 1 FPS -
     * see [Tizen 2x - Decoder Stalls on Playback over Period Boundaries](./tizen-2x.md#decoder-stalls-on-playback-over-period-boundaries).
     * This appears to happen when a new period (or any representation) is encountered with a
     * different codec or timescale to the current one (and possibly other attributes). This is
     * backed up by the fact that when returning to the first played codec or timescale
     * (e.g. after the ad-break), the decoder recovers and the framerate returns to normal.
     * We mitigate this by setting `replaceMediaSourceOnPeriodChange` to force flush the decoder.
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_multi_period*.mpd`,
     * CH 94, CH 10 and observing playback.
     */

    public replaceMediaSourceOnPeriodChange = false;

    /**
     * A boolean dictating whether values on the presentation timeline should be
     * constrained. This is necessary for some legacy targets which can not handle
     * presentation timeline values greater than 1 million.
     *
     * @configurable
     *
     * @remark
     * On Tizen 2.x, presentation time values greater or equal to 1 million (typically seen during
     * live events only) can not be handled by the platform and result in erratic jumps to arbitrary
     * negative `currentTime` values during playing (e.g. -21412342) -
     * see [Tizen 2x - High presentation timeline values](./tizen-2x.md#high-presentation-timeline-values).
     * This can be avoided by detecting such values and shifting the entire presentation timeline to fall
     * within the range 0-1000000. We may expect to see this behaviour on older, or more low-powered devices.
     *
     * @test
     * Test whether a change is needed here by Simulating a Live DASH Stream and observing playback.
     */

    public constrainPresentationTimeline = false;

    /**
     * The maximum number of messages (logs, warnings, and errors) to hold onto in memory.
     * Maintaining an internal history of messages at all times by default allows for
     * "after-the-fact" debugging, if debug logging is disabled at the point that a
     * specific error or behaviour occurs that warrants further investigation.
     *
     * @configurable
     *
     * @remark
     * Mercury currently records a rolling history of the 500 most recent Mercury logs, that are
     * ultimately sent to New Relic on error for engineer observation. On some future platforms
     * with extremely limited memory, it is possible to limit the number of rolling Mercury logs
     * in order to save memory. Doing so will ultimately reduce visibility over playback issues
     * and make issues on the platform harder to debug. The structure in which the logs are stored
     * is fairly efficient and has minimal impact on memory usage, so we've never had to lower this
     * value. If you find yourself considering this, think about if there are any other areas in
     * which memory consumption can be reduced instead.
     *
     * @test
     * Testing memory usage will be best done in the whole app, as Mercury in isolation tends to be
     * small enough to work across all current platforms.
     */

    public maxMessageHistoryLength = 500;

    /**
     * A boolean dictating whether logger messages should be directly passed through to the consumer
     * application (e.g. a wrapping Cadmium solution), or stored in memory and made available to the
     * debug view.
     *
     * @configurable
     *
     * @remark
     * When enabled, messages will not be stored in Mercury's message history, and the debug view
     * shall be disabled. These responsibilities are then delegated to the consumer.
     */

    public messageHistoryPassThrough = false;

    /**
     * Defines the logger message level to be used by Mercury. Messages below this level will not be emitted.
     * The default is MessageType.LOG which allows messages from log level and above to be emitted.
     *
     * @configurable
     *
     * @remark
     * Not to be confused with the logger verbosityLevel which only controls the level of messages sent to the console.
     */
    public messageFilterLevel = MessageType.LOG;

    /**
     * Determines if a playback rate of `0` should be allowed, or treated as a stall.
     * Provides a useful backdoor for simulating decoder stalls when set to `false`.
     */

    public allowZeroPlaybackRate = true;

    /**
     * Determines the offset from the end of the video used when seeking.
     * On static manifests to ensure that we show the last video frame to the user,
     * and get an `ended` event from the video element, we need to seek to a time
     * which is less than the duration of the video. If the offset is too small
     * then the end of the video might be shown to the user but the `ended` event
     * may not be sent.
     *
     * @configurable
     *
     * @remark
     * At the time of writing on Tizen 2.x we set this to 2s, and to 0s on Movistar. All other
     * targets use the default value. If a synthetic ended event is triggered before a native
     * ended state is achieved, then we can force a seek to the absolute duration to encourage
     * a native `ended` state using `seekToDurationOnEnded`. The combination of these values
     * must be carefully considered in order to reliably achieve a native ended state.
     *
     * @test
     * Test whether a change is needed here by playing any VOD content and whether a native ended
     * event is emitted. Given that the event in unreliable, one run will not be representative.
     */

    public safeSeekEndOffsetSeconds = 1.1;

    /**
     * A boolean dictating whether the `.endOfStream()` method can be safely called on the
     * media element once final VOD segments are appended.
     *
     * NB: On some targets such as movistar, this can result in the media source being torn down,
     * and therefore must be avoided in favour of "synthetic" ended behaviour.
     *
     * @configurable
     *
     * @remark
     * On most MSE platforms, we must call .endOfStream() on the media source after the final VOD segments
     * have been appended to instruct the platform that an ended event should be dispatched upon their play
     * through. For whatever reason, when we call this method on the Movistar box, the video element becomes
     * completely unusable after an ended state is achieved, and any subsequent interaction will cause a
     * media element exception -
     * see [Movistar - ended events and ended Behaviour](./movistar.md#ended-events-and-ended-behaviour).
     * A native ended state can still be achieved, simply by playing up the duration that has been set, albeit
     * without an ended event being dispatched. In this case a synthetic ended event is emitted.
     *
     * @test
     * Test whether a change is needed here by playing any VOD content and observing end of stream behaviour.
     * Given that the event in unreliable, one run will not be representative.
     */

    public canCallEndOfStream = true;

    /**
     * A boolean dictating whether we should attempt to force a native ended state on detection
     * of a synthetic ended event, by seeking the video element's `currentTime` to it's `duration`.
     * On some platforms (e.g. movistar), this will yield a native ended state. On others
     * (e.g. Tizen), it may unintentionally cause an auto-replay of the video and should therefore
     * be disabled.
     *
     * @configurable
     *
     * @remark
     * Although movistar rarely dispatches native ended events, we can force it to enter an native
     * ended state by seeking to the video element duration -
     * see [Movistar - ended events and ended Behaviour](./movistar.md#ended-events-and-ended-behaviour).
     * In this case we use `seekToDurationOnEnded` to essentially override `safeSeekEndOffsetSeconds`.
     * In most cases we should prefer the use of `safeSeekEndOffsetSeconds` and only seek to use
     * `seekToDurationOnEnded` when ended events are wholly unreliable.
     *
     * @test
     * Test whether a change is needed here by playing any VOD content and whether a native ended event
     * is emitted. Given that the event in unreliable, one run will not be representative.
     */

    public seekToDurationOnEnded = false;

    /**
     * A boolean which toggles the playback quality feature.
     *
     * @configurable
     *
     * @remark
     * On some platforms (Panasonic, Sky Q and Netgem) there is no `getVideoPlaybackQuality` API. In this case,
     * usage within Mercury can be turned off completely by toggling this value to avoid console errors.
     * Playback quality metrics are used for reporting purposes ONLY - to identify when frames are being
     * dropped at an accelerated rate, which can help us to understand the general playback experience
     * and player stall cause. The `getVideoPlaybackQuality` API is not always considered reliable,
     * even when it exists. The data should be taken with a pinch of salt for any platform other than
     * modern web browsers. We should always prefer to turn off the use of playback quality metrics rather
     * than polyfill it.
     *
     * @test
     * Test whether a change is needed here by playing any content and observing the console.
     */

    public canUsePlaybackQualityMetrics = true;

    /**
     * A boolean which toggles the localStorage feature.
     *
     * @configurable
     *
     * @remark
     * On some future platforms Local Storage may not be enabled, or we be asked by the distributor
     * not to use it. In this case it usage within Mercury can be turned off completely by toggling
     * this value. Local Storage is used to persist a number of values across playback sessions including
     * bandwidth estimates and debugging preferences. The the bandwidth estimate is the only value currently
     * relevant to the user - without this playback sessions will always begin at the lowest quality and
     * scale up depending on the users connection, rather than starting at the most appropriate variant
     * for that user.
     *
     * This is not the same as `canRetainBandwidthEstimate` in `ConfigAbr` which controls whether the
     * bandwidth estimate is retained from one load request to the next.
     *
     * @test
     * Test whether a change is needed here by playing any content and observing the console.
     */

    public canUseLocalStorage = true;

    /**
     * Determines the maximum interval between bandwidth estimate write operations.
     *
     * @configurable
     *
     * @remark
     * On some future platforms the frequent use Local Storage may be restricted, either due to performance
     * issues or at the request of the distributor (as frequent Local Storage rights can shorten the life of
     * the device storage). In this case it is possible to configure Mercury to write the bandwidth estimate
     * to Local Storage at a less frequent interval. This may be preferred to turning Local Storage off
     * completely, as playback sessions will still be able to start at a variant most appropriate variant for
     * that user, albeit potentially a less accurate one.
     *
     * @test
     * No test is required, the expectation being we would only turn this down on request.
     */

    public bandwidthEstimateMaxWriteIntervalMs = 2000;

    /**
     * A boolean dictating whether it is safe to call load on a video element
     * as part of media source tear down. On Sky Q  platform this causes CDM_UNAVAILABLE issue
     * due to a bug on Sky side.
     * See https://livesport.atlassian.net/wiki/x/xQFJkwE
     *
     * @configurable
     *
     * @test
     * Test whether a change is needed here by playing any content and observing playback unload behaviour.
     * Given that the event in unreliable, one run will not be representative.
     */

    public canVideoElementLoadDetachedSource = true;

    /**
     * A boolean dictating whether it is safe to set `videoElement.src` to an empty string
     * as part of media source tear down. On some platforms (e.g. movistar), this can significant
     * cause thread blocking, and does not seem to be necessary.
     *
     * @configurable
     *
     * @remark
     * On most MSE platforms, Mercury unsets `videoElement.src` when tearing down due error or unload,
     * as recommended by the W3C implementor guidelines for MSE. On Movistar this causes significant
     * thread blocking - see [Movistar - MSE/EME Tear Down](./movistar.md#mseeme-tear-down). In any case
     * the action has no noticeable effect on functionality, so we set `canSetEmptyVideoElementSource`
     * to ignore this step.
     *
     * @test
     * Test whether a change is needed here by playing any content and observing playback unload behaviour.
     * Given that the event in unreliable, one run will not be representative.
     */

    public canSetEmptyVideoElementSource = true;

    /**
     * The "splice_event_id" value that signals the end of a live event. This is contained in the binary
     * payload of a SCTE35 timeline region
     *
     */

    public endOfLiveEventSpliceId = 305;

    /**
     * Determines the rate at which to set the current time to itself after a
     * "loadedmetadata" event. On some platforms, this can help to recover a
     * stalled video element at startup.
     *
     * @configurable
     *
     * @remark
     * Sky Q will occasionally experience a startup time in excess of 20
     * seconds. Whilst a root cause is not known, we have seen that it can be
     * avoided by setting the currentTime to itself after an "loadeddata" event.
     * We do not know if this affects other platforms.
     *
     * @test
     * It will not be immediately clear through FABRiC testing whether this is
     * required. For Sky Q, we were able to identify the issue by thrashing
     * non-drm asset loads in FABRiC and seeing if any stalled. When a stall
     * occurred, we could verify the effectiveness of this fix by updating the
     * video element current time. We also saw a large percentage of sessions in
     * Conviva with a 20s+ startup time, indicating some form of stall.
     */

    public resetCurrentTimeOnLoadedDataPollIntervalMs = -1;

    /**
     * Determines if an error from the media element during the tear down process should be reported as warning rather
     * than as an error.
     *
     * @remark
     * This feature flag was introduced for the Movistar platform which threw a media element during the tear down
     * process even though the correct process was followed and the error didn't have an impact on the next load of
     * media. This error was not seen when Mercury was tested in isolation.
     */
    public warnOnErrorDuringTearDown = false;

    /**
     * A boolean dictating whether logic for coercing current time within seekable range
     * should be applied on time update event
     *
     * @remark
     * In Xbox, it is observed that setting the time by executing clamp time on seek is
     * unable to set the time consistently, which is reflected in the following timeupdate
     * event. Hence the call is repeated in time update event following a seek to enforce
     * the clamp, if the clamping logic from seek handler didn't take effect.
     *
     * @test
     * Test whether a change is needed here with multiple runs of seek on live assets related FABRiC
     * tests.
     */
    public clampCurrentTimeOnTimeUpdate = false;

    /**
     * A boolean dictating whether a static encrypted init fragment should be prepended
     * to source buffer (audio and video) before any actual stream data is appended
     *
     * @remark
     * In Xbox, it is observed that switching from unencrypted to encrypted
     * period/content when playback starts from an unencrypted period/content, which
     * results into issues, ranging from green screen, blocky artefact to app crash
     * (observed only in Xbox Series S).
     * Prepending an encrypted init fragment, even though fake, is able to address
     * the issue by kicking off the playback with an encrypted fragment and puts the
     * MSE player into a "DRM-friendly" mode.
     *
     * @test
     * Test whether a change is needed here with multiple runs of FABRiC tests related
     * to transition from unencrypted to encrypted periods
     */
    public prependFakeEncryptedInitFragment = false;

    /**
     * A boolean indicating whether the video element ended event can be used.
     *
     * @remark
     * On SkyQ Falcon V2, it is observed that multiple ended events are sent
     * On Sky Q(Titan/Falcon v2/ Falcon D1) devices, it is observed that the playing event with videoElement.ended as true
     * on replay or seek-back from the end of the content after getting into the ended state. which is triggering ended event
     *
     *  Turning this flag off, ignores the ended events and relies on the synthetic ended event logic instead.
     */
    public canUseVideoElementEndedEvent = true;

    /**
     * Each playback rate change creates a small audio/video desync, so after
     * a while we have to create a visible/audible stutter to realign them.
     *
     * See github.com/getndazn/mercury/blob/master/src/Core/Features/AdaptivePresentationDelay/README.md
     */
    public resyncAfterPlaybackRateChanges = false;

    /**
     * The threshold for audio/video desync becoming noticable was between 30
     * and 50 playback rate changes.
     *
     * See github.com/getndazn/mercury/blob/master/src/Core/Features/AdaptivePresentationDelay/README.md
     */
    public maxPlaybackRateChangesBeforeResync = 40;

    /**
     * A boolean indicating whether the variable playback rate can be set on the
     * video element in Mercury (e.g Adaptive Presentation Delay feature) and
     * when set it accurately changes playback rate to the value specified.
     *
     * An unexpected playback rate change warning will be sent when the platform
     * doesn't have variable playback rate support
     * (`canSupportVariablePlaybackRate: false`) but the playback rate changes
     * outside of Mercury's control anyway.
     *
     * Given web browsers have variable playback rate support and the playback
     * rate is highly unlikely to change on LR platforms outside of Mercury's
     * control, a warning should never be sent.
     *
     * @remark
     * WebOS, Panasonic and SkyQ (Beethoven and SkyQ) devices has playback rate
     * API on video element but variable playback rate doesn't change how
     * current time is progressed
     */
    public canSupportVariablePlaybackRate = false;

    /**
     * A boolean flag to indicate if the codec log message should be dispatched to New Relic
     *
     * @remark When the codec logging was introduced it was always enabled.
     * It was observed that the logging and dispatching of the active variant's codec at every variant change was generating
     * a huge set of data. Based on the follow up discussion, it was agreed to limit the dispatching of codec logging messages
     * only on-need basis (Example: To debug a failure due to codec mismatch on a customer device). This will be controlled via
     * a feature flag which could potentially be turned on for the particular viewerId/deviceId based on need.
     */
    public canDispatchCodecLoggingMessage = false;
}

export {ConfigBehaviour};
