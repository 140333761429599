import type {State, Store} from '@dazn/depo';
import {BehaviorSubject} from 'rxjs';

import {Manifest} from '@Shared/Models/Manifest';

import {StoreName} from '../../Constants/StoreName';

import {resolveManifestActions} from './Actions/resolveManifestActions';

const createManifestStore = (state: State): Store<Manifest, typeof resolveManifestActions, BehaviorSubject<Manifest>> =>
    state.createStore({
        model: Manifest,
        name: StoreName.MANIFEST,
        actions: resolveManifestActions,
        observable: BehaviorSubject,
    });

export {createManifestStore};
