import type {FC} from 'react';

type TErrorProps = {
    children: string;
};

const ErrorMessage: FC<TErrorProps> = ({children}: {children: string}) => (
    <div className="ErrorMessage">
        <span className="ErrorMessage__message">{children}</span>
    </div>
);

export {ErrorMessage};
