import {round} from './round';

/**
 * Rounds a given floating point input to no more or no less than the required amount of precision to uniquely
 * and accurately identify its value in seconds, using the provided `timescale` to determine the amount of
 * precision.
 *
 * # Why do we need this function?
 *
 * - Firstly, MPDs and encoders deal with arbitrary timescale units, but the HTML5 <video> API and its extensions
 * deal with seconds. Units in seconds are also easier to grep and debug for humans. For this reason
 * Mercury stores all stateful timing data in seconds rather than timescale units.
 *
 * - The process of converting values in timescale units to values in seconds almost always results in floating
 * point numbers with fractional parts.
 *
 * - A common process in placing/modelling an individual segment from a <SegmentTimeline> "range" (<S>) is to
 * calculate its end time in seconds by adding its duration to its start time, or to calculate its start time by
 * multiplying its duration by its index. This involves arithmetic operations on multiple floating point
 * numbers.
 *
 * - A well-known problem with the IEEE-754 number system used by JavaScript is rounding inaccuracies that appear
 * when performing arithmetic on them such as the operations described above. For example:
 *
 * @example:
 * ```
 * 0.1 + 0.2 // 0.30000000000000004
 * ```
 *
 * - Therefore we must take extreme care to suppress these issues by applying the _correct_ amount of rounding.
 *
 * - Too little rounding will lead to inaccurate values which will lead to _non_deterministic_ segment timeline
 * positions over multiple MPD updates (given a rolling DVR window where the position of the first segment in a range
 * is constantly changing).
 *
 * - Too much rounding and our values will not be precise enough to uniquely identify the
 * position of one segment from another. NB: We do not control the segment duration so we _must_ assume the smallest
 * possible segment duration we need to be able to model is `1` timescale unit in seconds, for any given timescale.
 *
 * - The goal is to therefore derive the _exact_ amount of required rounding from the `@timescale` attribute
 * of any given <SegmentTemplate>.
 *
 * # When should we use this function?
 *
 * - This function should be used whenever we need to perform arithmetic (addition, subtraction, division
 * multiplication) on multiple time-sensitive floating point values relating to the segment timeline. Potentially
 * inaccurate values with rounding errors should never be stored in state.
 *
 * # How does the function work?
 *
 * 1. Get the value of 1 timescale unit in seconds by dividing 1 by the timescale.
 *
 * 2. For any non-1 timescale, the result will always be less than 1. We are concerned with the
 * order of magnitude of this value, as that will determine the required precision to represent it.
 *
 * 3. Therefore we extract and operate on the "fractional part" of the resulting value (the numbers after
 * the decimal point).
 *
 * 4. To understand the order of magnitude, we count the number of zeros before the first-non zero number,
 * and add 1 to yield the index of that number.
 *
 * # Examples
 *
 * The following examples will show the risks of adding too much or too little precision, with the imaginary
 * minimum possible segment duration of 1 timescale unit `@d="1"`:
 *
 * @example
 *
 * #### Timescale = `3000` / Seconds = `0.000333333r` / Precision = `4` (optimum precision)
 *
 * | Index | Precision | Timescale Units | Seconds |
 * | ----- | --------- | --------------- | ------- |
 * | 0     | 5         | 0               | 0       |
 * | 1     | 5         | 1               | 0.0003  |
 * | 2     | 5         | 2               | 0.0007  |
 * | 3     | 5         | 3               | 0.001   |
 * | 4     | 5         | 4               | 0.0013  |
 * | 5     | 5         | 5               | 0.0017  |
 *
 * Result: For each index, values in seconds are deterministic and unique ✅
 *
 * @example
 *
 * #### Timescale = `3000` / Seconds = `0.000333333r` / Precision = `3` (too little precision)
 *
 * | Index | Precision | Timescale Units | Seconds |
 * | ----- | --------- | --------------- | ------- |
 * | 0     | 4         | 0               | 0       |
 * | 1     | 4         | 1               | 0       |
 * | 2     | 4         | 2               | 0       |
 * | 3     | 4         | 3               | 0.001   |
 * | 4     | 4         | 4               | 0.001   |
 * | 5     | 4         | 5               | 0.002   |
 *
 * Result: for many indices, the values in seconds are not unique to the timescale input ❌
 *
 * @example
 *
 * #### Timescale = `1000` / Seconds = 0.001 / Precision = `3` (optimum precision)
 *
 * | Index | Precision | Timescale Units | Seconds |
 * | ----- | --------- | --------------- | ------- |
 * | 0     | 3         | 0               | 0       |
 * | 1     | 3         | 1               | 0.001   |
 * | 2     | 3         | 2               | 0.002   |
 * | 3     | 3         | 3               | 0.003   |
 * | 4     | 3         | 4               | 0.004   |
 * | 5     | 3         | 5               | 0.005   |
 * | 5     | 3         | 6               | 0.006   |
 * | 5     | 3         | 7               | 0.007   |
 * | 5     | 3         | 8               | 0.008   |
 * | 5     | 3         | 9               | 0.009   |
 *
 * Result: For each index, values in seconds are deterministic and unique ✅
 *
 * @example
 *
 * #### Timescale = `1000` / Seconds = 0.001 / Precision = `∞` (too much precision)
 *
 * | Index | Precision | Timescale Units | Seconds |
 * | ----- | --------- | --------------- | ------- |
 * | 0     | ∞         | 0               | 0       |
 * | 1     | ∞         | 1               | 0.001   |
 * | 2     | ∞         | 2               | 0.002   |
 * | 3     | ∞         | 3               | 0.003   |
 * | 4     | ∞         | 4               | 0.004   |
 * | 5     | ∞         | 5               | 0.005   |
 * | 5     | ∞         | 6               | 0.006   |
 * | 5     | ∞         | 7               | 0.007   |
 * | 5     | ∞         | 8               | 0.008   |
 * | 5     | ∞         | 9               | 0.009000000000000001 |
 *
 * Result: For some inputs, IEEE-754 rounding errors are introduced ❌
 */

const roundToTimescalePrecision = (num: number, timescale: number): number => {
    // e.g. 12800 timescale units per second, 1 tick = 0.00008 seconds, 5 decimal places required
    const timescaleUnitInSeconds = 1 / timescale;
    // Split the value in seconds into its integer and fractional parts
    const [, fractionalPartAsString] = timescaleUnitInSeconds.toString().split('.');
    const precision = getPrecisionFromFractionalPart(fractionalPartAsString);

    return round(precision)(num);
};

const zerosRegExp = /(0+)/;

/**
 * Determines the amount of precision (decimal places) needed from a
 * given fractional part, by counting the number of zeros and adding 1
 */

const getPrecisionFromFractionalPart = (fractionalPartAsString: string): number => {
    if (!fractionalPartAsString) return 0;

    const match = zerosRegExp.exec(fractionalPartAsString);
    const zeros = match ? match[1].length : 0;

    return zeros + 1;
};

export {roundToTimescalePrecision};
