/**
 * See: https://medium.com/@abhinav.mahapatra10/beginners-ml-basics-exponentially-weighted-moving-average-8ce3e75768f6
 */

const updateEwmaValue = (currentValue: number, latestSample: number, halfLife: number): number => {
    if (currentValue < 0) return latestSample;

    const alpha = Math.exp(Math.log(0.5) / halfLife);

    return currentValue * alpha + latestSample * (1 - alpha);
};

export {updateEwmaValue};
