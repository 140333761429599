import type {FC} from 'react';
import {useState} from 'react';

import type {TMessage} from '@Logger/Types/TMessage';
import type {TEnumerationValue} from '@Shared/Util/enumeration';
import {enumeration} from '@Shared/Util/enumeration';

// TODO: Use when parsing CID from query string
// import {useQueryString} from '../useQueryString';

import {ErrorMessage} from './ErrorMessage';
import {Results} from './Results';
import {Welcome} from './Welcome';

import '../../assets/style.css';

const View = enumeration({
    WELCOME: 0,
    RESULTS: 1,
    ERROR: 2,
});

type TView = TEnumerationValue<typeof View>;

const App: FC = () => {
    const initialValue = '';
    // TODO: Use when parsing CID from query string
    // const [rawContent, setRawContent] = useQueryString('cid', initialValue);
    const [rawContent, setRawContent] = useState<string>(initialValue);
    const [data, setData] = useState<TMessage[]>([]);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const clearErrorMessage = setErrorMessage.bind(null, '');
    const hasErrorMessage = errorMessage !== '';

    const updateContent = (value: string) => {
        let rtn;

        try {
            rtn = JSON.parse(value);
            clearErrorMessage();
        } catch (err: unknown) {
            rtn = [];
            if (err instanceof Error) {
                if (value === '') {
                    // If value is an empty string, ignore the error message
                    clearErrorMessage();
                } else {
                    setErrorMessage(err.message);
                }
            }
        }

        setData(rtn);
        setRawContent(value);
    };

    let currentView: TView = View.WELCOME;

    // cannot import hasElements
    if (!hasErrorMessage && data.length > 0) currentView = View.RESULTS;
    if (hasErrorMessage) currentView = View.ERROR;

    const GITHUB_SHA = process.env.GITHUB_SHA || '0000000';
    const BRANCH_NAME = process.env.BRANCH_NAME || 'unknown-branch';
    const TIMESTAMP = process.env.TIMESTAMP || 'YYYY-MM-DD HH:MM:SS';

    return (
        <>
            <form>
                <section>
                    <textarea
                        value={rawContent}
                        onChange={e => updateContent(e.target.value)}
                        placeholder="Paste the `context.messages` property of a NewRelic log here"
                    />
                </section>
            </form>
            <section>
                {currentView === View.WELCOME && <Welcome />}
                {currentView === View.RESULTS && <Results data={data} />}
                {currentView === View.ERROR && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </section>
            <footer>
                Built at {TIMESTAMP} against{' '}
                <a href={`https://github.com/getndazn/mercury/commit/${GITHUB_SHA}`}>
                    {BRANCH_NAME} (#{GITHUB_SHA.substring(0, 7)})
                </a>
            </footer>
        </>
    );
};

export {App};
