import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import type {TValidStreamType} from '@Shared/Types/TValidStreamType';

import {FeatureMemoryManagementMessageId} from './FeatureMemoryManagementMessageId';

const featureMemoryManagementMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [FeatureMemoryManagementMessageId._000_SOURCE_BUFFER_QUOTA_EXCEEDED]: (
            sourceBufferType: TValidStreamType,
        ): string => `${sourceBufferType} Source Buffer QuotaExceededError reported`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureMemoryManagementMessages = TLoggerMessages<
    MessageNamespace._035_FEATURE_MEMORY_MANAGEMENT,
    typeof featureMemoryManagementMessages
>;

export {featureMemoryManagementMessages};
