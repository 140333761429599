type TPredicate<T> = (value: T, index: number, obj: T[]) => unknown;

const findIndex = <T>(arr: T[], predicate: TPredicate<T>, reverse: boolean = false): number => {
    let initializationIndex = 0;
    let finishCondition = (i: number, arrayLength: number): boolean => i < arrayLength;
    let afterthoughtCondition = (i: number): number => ++i;

    if (reverse) {
        initializationIndex = arr.length - 1;
        finishCondition = (i: number): boolean => i >= 0;
        afterthoughtCondition = (i: number): number => --i;
    }

    for (let i = initializationIndex; finishCondition(i, arr.length); i = afterthoughtCondition(i)) {
        const match = Boolean(predicate(arr[i], i, arr));

        if (match) return i;
    }

    return -1;
};

export {findIndex};
export type {TPredicate};
