import {deepMergeWithArrayOverride} from '@Player/Util/deepMergeWithArrayOverride';
import type {TDeepPartial} from '@Shared/Types/TDeepPartial';

import type {Config} from '../Models/Config';

type TConfigActions = {
    configure(nextConfig: TDeepPartial<Config>): void;
};

const resolveConfigActions = (value: Config): TConfigActions => ({
    configure(nextConfig) {
        deepMergeWithArrayOverride(value, nextConfig);
    },
});

export type {TConfigActions};
export {resolveConfigActions};
