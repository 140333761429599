import type {FC} from 'react';
import {useState} from 'react';

const Welcome: FC = () => {
    const [isCopied, setIsCopied] = useState(false);
    const copy = (): void => {
        navigator.clipboard.writeText('copy(window.player._doNotUse.solution.mercury.getMessageHistory())');
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    return (
        <div className="Welcome">
            <p className="Welcome__message">
                💡 To copy player messages directly from playback-web-player, use the following console command
            </p>
            <div className="Welcome__container">
                <code className="Welcome__copy">
                    copy(window.player._doNotUse.solution.mercury.getMessageHistory())
                </code>
                <button className="Welcome__copy" onClick={() => copy()}>
                    {isCopied ? `🙌` : `📋`}
                </button>
            </div>
        </div>
    );
};

export {Welcome};
