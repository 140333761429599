import {PlaybackStatusMachineEventType} from '../Constants/PlaybackStatusMachineEventType';
import {StalledStatus} from '../Constants/StalledStatus';

/**
 * Derives the most appropriate input event type for the playback status machine, based on a
 * change in stalled status. Some stalled statuses trigger playback status changes, but
 * most do not.
 */

const deriveEventTypeFromStalledStatus = (stalledStatus: StalledStatus): PlaybackStatusMachineEventType => {
    switch (stalledStatus) {
        case StalledStatus.STALLED_AWAITING_KEY_SESSION:
            return PlaybackStatusMachineEventType.STALL_AWAITING_KEY_SESSION;
        case StalledStatus.STALLED_CAUSE_UNKNOWN:
            return PlaybackStatusMachineEventType.STALL_CAUSE_UNKNOWN;
        default:
            return PlaybackStatusMachineEventType.STALL_CAUSE_EXPECTED;
    }
};

export {deriveEventTypeFromStalledStatus};
