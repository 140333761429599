import type {TTransformDrmRequest} from '../Types/TTransformDrmRequest';
import type {TTransformDrmResponse} from '../Types/TTransformDrmResponse';

class ConfigKeySystem {
    public licenseAcquisitionUrl: string = '';
    public transformRequest: TTransformDrmRequest | null = null;
    public transformResponse: TTransformDrmResponse | null = null;
}

export {ConfigKeySystem};
