import type {TEnumerationValue} from '@Shared/Util/enumeration';

// this enum is part of public API so will be removed in 2 steps
// eslint-disable-next-line
enum WidevineRobustness {
    EMPTY = '',
    SW_SECURE_CRYPTO = 'SW_SECURE_CRYPTO',
    SW_SECURE_DECODE = 'SW_SECURE_DECODE',
    HW_SECURE_CRYPTO = 'HW_SECURE_CRYPTO',
    HW_SECURE_DECODE = 'HW_SECURE_DECODE',
    HW_SECURE_ALL = 'HW_SECURE_ALL',
}

// it's used as gradual usage migration for consumers
type TWidevineRobustness = TEnumerationValue<typeof WidevineRobustness>;

export type {TWidevineRobustness};
export {WidevineRobustness};
