interface IGetSafeSeekRangeEndTimeParams {
    isDynamicAndNotEnded: boolean;
    safeSeekEndOffsetSeconds: number;
    seekRangeEndTimeSeconds: number;
    suggestedPresentationDelaySeconds: number;
    bufferAheadGoal: number;
    minPresentationDelay: number;
}

/**
 * Returns the seek range end time, minus the maximum of:
 * - `MPD@suggestedPresentationDelay`
 * - the configured `bufferAheadGoal`
 * - the configured `minPresentationDelay`.
 *
 * Used to prevent the player from joining, or seeking too close to the
 * live edge and incurring unnecessary prolonged re-buffering while it
 * waits for new segments to appear in the manifest.
 *
 * For static manifests, the raw `seekRangeEndTimeSeconds` minus a small
 * safety margin is returned, as the user may wish to seek to the end of
 * a VOD asset intentionally.
 */

const getSafeSeekRangeEndTimeSeconds = ({
    bufferAheadGoal,
    isDynamicAndNotEnded,
    minPresentationDelay,
    safeSeekEndOffsetSeconds,
    seekRangeEndTimeSeconds,
    suggestedPresentationDelaySeconds,
}: IGetSafeSeekRangeEndTimeParams): number => {
    const dynamicSafeSeekEndOffsetSeconds =
        seekRangeEndTimeSeconds - Math.max(suggestedPresentationDelaySeconds, bufferAheadGoal, minPresentationDelay);

    const staticSafeSeekEndOffsetSeconds = seekRangeEndTimeSeconds - safeSeekEndOffsetSeconds;

    return isDynamicAndNotEnded ? dynamicSafeSeekEndOffsetSeconds : staticSafeSeekEndOffsetSeconds;
};

export type {IGetSafeSeekRangeEndTimeParams};
export {getSafeSeekRangeEndTimeSeconds};
