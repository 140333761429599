import type {Metrics} from '../Models/Metrics';

import type {TMultiDomainActions} from './resolveMultiDomainActions';
import {resolveMultiDomainActions} from './resolveMultiDomainActions';
import type {TNetworkActions} from './resolveNetworkActions';
import {resolveNetworkActions} from './resolveNetworkActions';
import type {TPlaybackActions} from './resolvePlaybackActions';
import {resolvePlaybackActions} from './resolvePlaybackActions';
import type {TTimingActions} from './resolveTimingActions';
import {resolveTimingActions} from './resolveTimingActions';

type TMetricsActions = TMultiDomainActions & TNetworkActions & TPlaybackActions & TTimingActions;

const resolveMetricsActions = (value: Metrics, actions: TMetricsActions): TMetricsActions => ({
    ...resolvePlaybackActions(value),
    ...resolveNetworkActions(value),
    ...resolveTimingActions(value),
    ...resolveMultiDomainActions(value, actions),
});

export type {TMetricsActions};
export {resolveMetricsActions};
