import {DEFAULT_LANGUAGE} from '@Features/MultiTrackAudio/Constants/languages';

/**
 * All configuration related to Audio.
 *
 * @title Audio
 */

class ConfigAudio {
    /**
     * Preferred language for multi-track audio. Typically 2-3 digit ISO code although
     * not guaranteed to align ISO standards. Unset when DEFAULT_LANGUAGE.
     */

    public language: string = DEFAULT_LANGUAGE;

    /**
     * Preferred codec will be used in Manifest Parsing to select an audio Adaptation set over another
     * when multiple adaptation sets (of different codecs) are present in the Manifest.
     */
    public preferredCodec?: string;
}

export {ConfigAudio};
