/* eslint-disable max-len */
import {StallRecoveryStrategy} from '@Shared/Constants/StallRecoveryStrategy';
/**
 * All configuration related to playback stall recovery.
 *
 * @configurable
 * @title Stall Recovery
 *
 * @remark
 * The Stall Recovery configurations can and should be configured per platform,
 * to give it the best possible chance to recover gracefully from playback stalls.
 * A bad configuration will lead to higher Connection-induced Rebuffering Ratio (CIRR),
 * Video Playback Failures (VPF) and `09002` unrecoverable stall errors - accounting
 * for over 90% of Mercury errors at the time of writing.
 * See [Mercury Runbook - New Relic dashboards](https://livesport.atlassian.net/wiki/spaces/PLAYA/pages/5482251080/Mercury+Runbook#Where-can-I-find-Mercury-dashboards?),
 * where our dashboards show (at the time of writing) a stall recovery rate of 73% on
 * web contributes to 0.3% CIRR and 3.5% VPF. Compare that to LG WebOS, which has a
 * recovery rate of 93%, 0.1% CIRR and 1.16% VPF.
 */

class ConfigStallRecovery {
    /**
     * An ordered list of zero or more "recovery strategies" to attempt before
     * erroring out, upon detection of a stall.
     *
     * @configurable
     *
     * @remark
     * There are no reliable means to force a _real_ stall on a device, so setting the correct
     * strategies can feel much like a guessing game. Researching online, or with the platform
     * distributor, may indicate what strategies can be effective. Ultimately though, the best
     * option available to us is to stick with the default configuration and react to low stall
     * recovery rates in PROD - we can really only identify this issue at scale. In the future we
     * may wish to consider A/B testing configurations.
     */

    public strategies: StallRecoveryStrategy[] = [
        StallRecoveryStrategy.SEEK_FORWARD,
        StallRecoveryStrategy.DROP_AND_CAP_BITRATE,
    ];

    /**
     * The maximum time to wait after a strategy has been attempted, before deciding that it
     * did not succeed.
     *
     * @configurable
     *
     * @remark
     * The effectiveness of a stall strategy is dependent on how quick the Media Source can respond
     * to the recovery action. If time updates are emitted frequently (every 100ms or so) then we can
     * be fairly confident after two seconds (default value) of no updates that the strategy has not worked.
     * On the other hand, if time updates are emitted less frequently (say 1s on average), it will be much
     * less clear if a strategy has been successful. We may even throw an error for a session that
     * otherwise would have recovered. Whilst there is no reliable means to force a _real_ stall on
     * device, we can infer an appropriate value by manually monitoring the average time between current
     * time update emissions in the full app (you'll find it increases as we add weight to the app).
     * Set the value high enough to comfortable clear the average emission, without having it so long
     * that executing all stall strategies are no better than buffering until CDN rotation (15s/20s).
     * If you do see that time updates frequently take longer than a second, we may need to consider
     * wider performance of the app.
     */

    public strategiesRecoveryThresholdMs: Record<keyof typeof StallRecoveryStrategy, number> = {
        [StallRecoveryStrategy.PAUSE_THEN_PLAY]: 2000,
        [StallRecoveryStrategy.SEEK_FORWARD]: 2000,
        [StallRecoveryStrategy.EMPTY_BUFFERS]: 5000,
        [StallRecoveryStrategy.DROP_AND_CAP_BITRATE]: 5000,
    };

    /**
     * The maximum time to wait after a strategy has been attempted, before deciding that it
     * did not succeed.
     *
     * @configurable
     *
     * @remark
     * The effectiveness of a stall strategy is dependent on how quick the Media Source can respond
     * to the recovery action. If time updates are emitted frequently (every 100ms or so) then we can
     * be fairly confident after two seconds (default value) of no updates that the strategy has not worked.
     * On the other hand, if time updates are emitted less frequently (say 1s on average), it will be much
     * less clear if a strategy has been successful. We may even throw an error for a session that
     * otherwise would have recovered. Whilst there is no reliable means to force a _real_ stall on
     * device, we can infer an appropriate value by manually monitoring the average time between current
     * time update emissions in the full app (you'll find it increases as we add weight to the app).
     * Set the value high enough to comfortable clear the average emission, without having it so long
     * that executing all stall strategies are no better than buffering until CDN rotation (15s/20s).
     * If you do see that time updates frequently take longer than a second, we may need to consider
     * wider performance of the app.
     *
     * @deprecated
     * Timeouts are now configured per strategy - use strategiesRecoveryThresholdMs instead.
     */

    public recoveryThresholdMs = 2000;

    /**
     * The amount of progressive time updates (where the new current time is
     * greater than the previous) required to consider a stall as having
     * successfully recovered.
     *
     * @configurable
     *
     * @remark
     * Especially on devices where time updates are less-frequent, increasing
     * this value will make it harder for recovery to be achieved within the
     * recovery threshold. On most devices, the default value of 2 should be
     * sufficient.
     */

    public progressiveTimeUpdateThreshold = 2;

    /**
     * The amount to seek forward by, in seconds, when applying the
     * `SEEK_FORWARD` strategy.
     *
     * @configurable
     *
     * @remark
     * It should be very unlikely that this needs to changed, unless the player
     * is heavily rounding precision of the current time - so much so that 0.1s
     * change does not impact the `currentTime` value. See
     * [Seeking](./seeking.md#reduced-time-precision)).
     */

    public seekToRecoverDurationSeconds = 0.1;
}

export {ConfigStallRecovery};
