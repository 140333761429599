import type {Segment} from '@Shared/Models/Segment';
import {hasElements} from '@Shared/Util/hasElements';

import {getTimeSortedBufferRanges} from './getTimeSortedBufferRanges';

/**
 * NB: Assumes a single contiguous range, inclusive of small gaps.
 *
 * If multiple, non-contiguous ranges exist, only the first one will
 * be acknowledged.
 */

const getEndOfRange = (rangeMap: Record<string, Segment>, smallGapThresholdSeconds: number): number => {
    const sortedBufferRanges = getTimeSortedBufferRanges(rangeMap);

    if (!hasElements(sortedBufferRanges)) return 0;

    let i = 0;

    for (; i < sortedBufferRanges.length; i++) {
        const nextStartTime = sortedBufferRanges[i + 1] ? sortedBufferRanges[i + 1].startTimeSeconds : -Infinity;
        const gap = Math.max(0, nextStartTime - sortedBufferRanges[i].endTimeSeconds);

        // - Move through each range
        // - If we land on the final item, break before i can be incremented again
        // - If the start time of next key (i + 1) > the end time of this key, break (indicates a gap)
        // - NB: small gaps are tolerated, overlaps are tolerated (e.g. start time < end time),
        //   but equality is expected

        if (i === sortedBufferRanges.length - 1 || gap > smallGapThresholdSeconds) break;
    }

    return sortedBufferRanges[i].endTimeSeconds;
};

export {getEndOfRange};
