/* eslint-disable max-len */

interface IDepoLogArgs {
    storeName: string;
    actionName: string;
    wasBatchUpdate: boolean;
    wasLinkedUpdate: boolean;
    fallback: string;
}

/**
 * Breaks a depo log message string into its component parts to reduce the memory
 * footprint of our serialisable message history.
 *
 * Depo logs can appear in the following forms:
 * 1. 'Store "metrics" updated by action `setLifecycleStatus()`' // A single update caused by a known action
 * 2. 'Store "manifest" updated by action `setManifestUrl()` as part of batch' // An update within a batch caused by a know action
 * 3. 'Store "metrics" updated in reaction to linked store update' // A linked store update in reaction an action on another store
 * 4. 'Store "metrics" updated in reaction to linked store update as part of batch' // A linked store update in reaction an action on another store, within a batch
 * 5. 'Store "metrics" updated' // on older ES5 runtimes where `function.name` is undefined so no action name is provided (can apply to any of the above)
 *
 * NB: This function will fail if the structure of these logs changes in the future,
 * which is a private implementation detail. To accommodate for this, a `fallback`
 * argument is provided, which will pass the original message through untouched.
 */

const parseDepoLog = (msg: string): IDepoLogArgs => {
    const args: IDepoLogArgs = {
        storeName: '',
        actionName: '',
        wasBatchUpdate: false,
        wasLinkedUpdate: false,
        fallback: '',
    };

    // Simple checks to check for linked or batched flags

    args.wasLinkedUpdate = / linked /.test(msg);
    args.wasBatchUpdate = / of batch/.test(msg);

    // Capture groups to pull the store name and action name (between double quotes
    // and back ticks respectively).

    const match = msg.match(/"([a-zA-Z]+)"[a-z ]+(`([a-zA-Z]+)\(\)`|)/);

    if (match) {
        const [, storeName, , actionName] = match;

        args.storeName = storeName;
        args.actionName = actionName ?? ''; // Action name may not be present as per format #5 above
    } else {
        args.fallback = msg;
    }

    return args;
};

export type {IDepoLogArgs};
export {parseDepoLog};
