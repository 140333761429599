import merge from 'helpful-merge';
import type IConfig from 'helpful-merge/dist/Interfaces/IConfig';

const deepMergeWithArrayOverride = (target: any, source: any, options: IConfig = {}) =>
    merge(target, source, {
        ...options,
        deep: true,
        useReferenceIfArray: true,
    });

export {deepMergeWithArrayOverride};
