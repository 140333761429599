import {DEFAULT_LANGUAGE} from '@Features/MultiTrackAudio/Constants/languages';

interface IRenderSegmentLoggingNameParams {
    segmentIndex: number;
    variantIndex: number;
    language: string;
    periodId?: string;
    isInitSegment?: boolean;
}

const renderSegmentLoggingName = ({
    segmentIndex,
    variantIndex,
    language,
    periodId,
    isInitSegment,
}: IRenderSegmentLoggingNameParams): string => {
    let message = isInitSegment ? '' : `segment index ${segmentIndex}, `;

    message += `variant index ${variantIndex}`;

    if (typeof periodId === 'string') {
        message += `, period ID "${periodId}"`;
    }

    if (language !== DEFAULT_LANGUAGE) {
        message += `, language "${language}"`;
    }

    return message;
};

export {renderSegmentLoggingName};
