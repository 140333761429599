import type {TEnumerationValue} from '@Shared/Util/enumeration';

// this enum is part of public API so will be removed in 2 steps
// eslint-disable-next-line
enum DrmKeySystemId {
    WIDEVINE = 'com.widevine.alpha',
    PLAYREADY = 'com.microsoft.playready',
    FAIRPLAY = 'com.apple.fps.1_0',
    NONE = '',
}

// it's used as gradual usage migration for consumers
type TDrmKeySystemId = TEnumerationValue<typeof DrmKeySystemId>;

export type {TDrmKeySystemId};
export {DrmKeySystemId};
