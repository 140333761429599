type THumaniseUnixTimePresentation = 'dateAndTime' | 'dateOnly' | 'timeAndDayCount' | 'timeOnly';

const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

/**
 * Converts a provided unix wallclock time in ms to a human readable date time string.
 */

const humaniseUnixTime = (unixTimeMs: number, presentation: THumaniseUnixTimePresentation = 'dateAndTime'): string => {
    const dateTime = new Date(unixTimeMs).toISOString();

    if (presentation === 'timeAndDayCount') {
        return Math.floor(unixTimeMs / ONE_DAY_IN_MILLISECONDS).toString() + 'd ' + dateTime.substring(11, 23);
    }

    if (presentation === 'timeOnly') return dateTime.substring(11, 23);
    if (presentation === 'dateOnly') return dateTime.substring(0, 10);

    return dateTime.substring(0, 23).replace('T', ' ');
};

export {humaniseUnixTime, ONE_DAY_IN_MILLISECONDS};
export type {THumaniseUnixTimePresentation};
