import type {TOverloadedErrorMessage} from '@Logger/Types/TOverloadedErrorMessage';

import {PlayerError} from './PlayerError';

/**
 * A small factory over the `PlayerError` class, providing type safety on error
 * IDs and arguments via overloads, which is impossible directory on the `PlayerError`
 * constructor.
 *
 * !!-IMPORTANT-!!
 *
 * This function should only be called directly from within web workers, or code that
 * could be run inside a web worker (e.g. a manifest parser), where access to the player
 * instance's `ErrorManager` is not available.
 *
 * Errors thrown by this function will be caught and propagated to the `ErrorManager` via
 * web worker events as necessary.
 */

const throwPlayerError: TOverloadedErrorMessage = (message, id, ...args) => {
    throw new PlayerError(message, id, ...args);
};

export {throwPlayerError};
