import type {TIsCodecSupported} from '../Types/TIsCodecSupported';

let cache: Record<string, boolean> = {};

/**
 * An implementation of `TIsCodecSupported` for platforms that correctly
 * implement `MediaSource.isTypeSupported`
 */

const isCodecSupported: TIsCodecSupported = (codec, dependencies = {isTypeSupported: MediaSource.isTypeSupported}) => {
    codec = codec.toLocaleLowerCase();

    return cache[codec] ?? (cache[codec] = dependencies.isTypeSupported(codec));
};

const clearCache = (): void => {
    cache = {};
};

export {isCodecSupported, cache as verifiedCodecs, clearCache};
