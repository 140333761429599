import type {TEnumerationValue} from '@Shared/Util/enumeration';

// this enum is part of public API so will be removed in 2 steps
// eslint-disable-next-line
enum DrmType {
    ANY = 'ANY',
    WIDEVINE = 'WIDEVINE',
    PLAYREADY = 'PLAYREADY',
    FAIRPLAY = 'FAIRPLAY',
}

// it's used as gradual usage migration for consumers
type TDrmType = TEnumerationValue<typeof DrmType>;

export type {TDrmType};
export {DrmType};
