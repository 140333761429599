import type {BufferData} from '../Models/BufferData';

import {getTimeSortedBufferRanges} from './getTimeSortedBufferRanges';

const dropBufferedRange = (bufferData: BufferData, startSeconds: number, endSeconds: number): void => {
    const sortedBufferRanges = getTimeSortedBufferRanges(bufferData.bufferedRangeMap);
    let unsetPreviousBufferingSegment = false;

    for (const bufferRange of sortedBufferRanges) {
        const bufferedSegment = bufferData.bufferedRangeMap[bufferRange.id];

        if (bufferedSegment.startTimeSeconds >= startSeconds && bufferedSegment.endTimeSeconds <= endSeconds) {
            delete bufferData.bufferedRangeMap[bufferRange.id];
            unsetPreviousBufferingSegment = true;
        }
    }

    if (unsetPreviousBufferingSegment) bufferData.previousBufferingSegment = null;
};

export {dropBufferedRange};
