import type {DebugVerbosityLevel} from '@Logger/Constants/DebugVerbosityLevel';
import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';

import type {LocalStorage} from '../Models/LocalStorage';

type TLocalStorageActions = {
    addLoggingFilter(str: string): void;
    assignLoggingColourToNamespace(namespace: MessageNamespace, colour: string): void;
    clearLoggingFilters(): void;
    debug(enable: boolean, verbosityLevel: DebugVerbosityLevel): void;
    removeLoggingFilter(str: string): void;
    setMostRecentBandwidthEstimate(mostRecentBandwidthEstimate: number): void;
};

/**
 * Resolve Local Storage Actions
 *
 * @remark
 * WARNING: Depo no longer supports array object proxies. Any array in a Depo store must not be manipulated using array
 * functions. The whole array must be replaced in a single operation.
 */
const resolveLocalStorageActions = (value: LocalStorage): TLocalStorageActions => ({
    /**
     * Enables or disables debug logging in the console.
     */

    debug(enable, verbosityLevel) {
        value.debug = enable;
        value.verbosityLevel = verbosityLevel;
    },

    /**
     * Assigns a provided hex colour to a namespace for coloured console output.
     */

    assignLoggingColourToNamespace(namespace, colour) {
        value.loggingColours[namespace] = colour;
    },

    /**
     * Adds a new filter string which all logs will be filtered against.
     */

    addLoggingFilter(str) {
        const lowerCasedStr = str.toLowerCase();

        if (value.loggingFilters.indexOf(lowerCasedStr) > -1) return;

        // See WARNING in resolveLocalStorageActions comment before changing the following line of code.
        value.loggingFilters = [...value.loggingFilters, lowerCasedStr];
    },

    /**
     * Removes an existing filter string, if set.
     */

    removeLoggingFilter(str) {
        const lowerCasedStr = str.toLowerCase();

        const index = value.loggingFilters.indexOf(lowerCasedStr);

        if (index > -1) {
            const copy = [...value.loggingFilters];

            copy.splice(index, 1);

            // See WARNING in resolveLocalStorageActions comment before changing the following line of code.
            value.loggingFilters = copy;
        }
    },

    /**
     * Removes all active filter strings, such that all logs will be shown.
     */

    clearLoggingFilters() {
        // See WARNING in resolveLocalStorageActions comment before changing the following line of code.
        value.loggingFilters = [];
    },

    /**
     * Saves the most recent bandwidth estimate into the local storage store. The
     * browser's local storage will be updated in reaction.
     */

    setMostRecentBandwidthEstimate(mostRecentBandwidthEstimate) {
        value.mostRecentBandwidthEstimate = mostRecentBandwidthEstimate;
    },
});

export type {TLocalStorageActions};
export {resolveLocalStorageActions};
