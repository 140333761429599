import type {MessageNamespace} from '@Logger/Constants/MessageNamespace';
import {MessageType} from '@Logger/Constants/MessageType';
import type {TLoggerMessages} from '@Logger/Types/TLoggerMessages';
import {humaniseUnixTime} from '@Shared/Util/humaniseUnixTime';

import type {IVariantChangeIssue} from '../Interfaces/IVariantChangeIssue';

import {FeaturePlayerHealthMessageId} from './FeaturePlayerHealthMessageId';

interface IImbalanceInAudioVideoBuffers {
    bufferHealthStatus: string;
    currentTimeSeconds: number;
    audioBufferAheadDurationSeconds: number;
    videoBufferAheadDurationSeconds: number;
    audioBufferingAheadDurationSeconds: number;
    videoBufferingAheadDurationSeconds: number;
    audioDownloadingBufferingSegmentsCount: number;
    videoDownloadingBufferingSegmentsCount: number;
    hasActivePeriod: boolean;
    hasActiveVariant: boolean;
    activePeriodIndex: number;
    activeVariantIndex: number;
    manifestIsParsed: boolean;
    manifestIsActiveVariantParsed: boolean;
    playerHealthTickTimeSeconds: number;
    playerUpTimeSeconds: number;
    playerUpTimeTickCountDeltaSeconds: number;
}

const featurePlayerHealthMessages = {
    [MessageType.INFO]: {},
    [MessageType.LOG]: {},
    [MessageType.WARNING]: {
        [FeaturePlayerHealthMessageId._000_IMBALANCE_IN_AUDIO_VIDEO_BUFFERS]: ({
            bufferHealthStatus,
            currentTimeSeconds,
            audioBufferAheadDurationSeconds,
            videoBufferAheadDurationSeconds,
            audioBufferingAheadDurationSeconds,
            videoBufferingAheadDurationSeconds,
            audioDownloadingBufferingSegmentsCount,
            videoDownloadingBufferingSegmentsCount,
            hasActivePeriod,
            hasActiveVariant,
            activePeriodIndex,
            activeVariantIndex,
            manifestIsParsed,
            manifestIsActiveVariantParsed,
            playerHealthTickTimeSeconds,
            playerUpTimeSeconds,
            playerUpTimeTickCountDeltaSeconds,
        }: IImbalanceInAudioVideoBuffers): string =>
            `Imbalance in audio and video buffers at ${currentTimeSeconds}:` +
            `\n    Buffer health: ${bufferHealthStatus}` +
            `\n    Audio buffer ahead duration: ${audioBufferAheadDurationSeconds}s` +
            `\n    Video buffer ahead duration: ${videoBufferAheadDurationSeconds}s` +
            `\n    Audio buffering ahead duration: ${audioBufferingAheadDurationSeconds}s` +
            `\n    Video buffering ahead duration: ${videoBufferingAheadDurationSeconds}s` +
            `\n    Audio downloading count: ${audioDownloadingBufferingSegmentsCount}` +
            `\n    Video downloading count: ${videoDownloadingBufferingSegmentsCount}` +
            `\n    Has active period: ${hasActivePeriod}` +
            `\n    Has active variant: ${hasActiveVariant}` +
            `\n    Active period index: ${activePeriodIndex}` +
            `\n    Active variant index: ${activeVariantIndex}` +
            `\n    Manifest is parsed: ${manifestIsParsed}` +
            `\n    Manifest is active variant parsed: ${manifestIsActiveVariantParsed}` +
            `\n    Player health tick time seconds:` +
            ` ${humaniseUnixTime(playerHealthTickTimeSeconds ?? 0 * 1000, 'timeOnly')}` +
            ` (${playerHealthTickTimeSeconds})` +
            `\n    Player up time: ${humaniseUnixTime(playerUpTimeSeconds ?? 0 * 1000, 'timeAndDayCount')}` +
            `\n    Player up time tick count delta: ${playerUpTimeTickCountDeltaSeconds}`,
        [FeaturePlayerHealthMessageId._001_RAPID_VARIANT_CHANGE]: (issue: IVariantChangeIssue) =>
            `Seen ${issue.count} variants within ${issue.windowMs}ms (limit ${issue.limit})`,
    },
    [MessageType.ERROR]: {},
};

export type TFeatureBufferHealthMessages = TLoggerMessages<
    MessageNamespace._031_FEATURE_PLAYER_HEALTH,
    typeof featurePlayerHealthMessages
>;

export {featurePlayerHealthMessages};
