import {BufferHealthStatus} from '../Constants/BufferHealthStatus';

const bufferHealthStatusPrecedenceMap = {
    [BufferHealthStatus.EMPTY]: 0,
    [BufferHealthStatus.CRITICAL]: 1,
    [BufferHealthStatus.UNHEALTHY]: 2,
    [BufferHealthStatus.HEALTHY]: 3,
};

const getBufferHealthStatusPrecedence = (bufferHealthStatus: BufferHealthStatus): number =>
    bufferHealthStatusPrecedenceMap[bufferHealthStatus];

export {getBufferHealthStatusPrecedence};
