/**
 * All configuration related to ABR.
 *
 * @configurable
 * @title Adaptive Bitrate (ABR)
 *
 * @remark
 * The majority of the ABR configurations define fixed values for the Mercury heuristic,
 * things like what should happen when we hit certain buffer ahead goals and how quickly
 * Mercury should respond to bandwidth estimation changes. These values should not be
 * changed per target. Doing so would cause inconsistent behaviour across platforms and
 * may break core playback functionality e.g. setting a bufferAheadGoal value of 5 to save
 * bandwidth would mean Mercury never increased the variant midstream due to a
 * switchUpBufferThresholdMidstream value of 8.
 */

class ConfigAbr {
    /**
     * The maximum supported bitrate for the target device.
     */

    public maxBitrate = Infinity;

    /**
     * The half-life of any values added to the "fast" EWMA bandwidth estimate.
     */

    public ewmaHalfLifeFast = 2;

    /**
     * The half-life of any values added to the "slow" EWMA bandwidth estimate.
     */

    public ewmaHalfLifeSlow = 6;

    /**
     * The amount of buffered content ahead of the current time (in seconds) that
     * the player should strive for when playing a static (aka non-live) manifest.
     */

    public bufferAheadGoalStaticSeconds = 12;

    /**
     * The amount of buffered content behind the current time (in seconds) that
     * the player should hold on to.
     */

    public bufferBehindGoalSeconds = 5;

    /**
     * The number of seconds of audio buffer required before video takes precedence, when the video buffer already has
     * at least the same number of seconds.
     *
     * @remark
     * This value is used to ensure that video is not prioritised when the audio buffer is discarded on an audio track
     * change. When the audio buffer is discarded the video buffer will under most circumstances be full and so we need
     * to ensure that the filling of the audio buffer is not delayed by making unnecessary requests for video segments.
     */
    public audioPrecedenceInBufferAheadSeconds = 4;

    /**
     * A boolean indicating whether the platform can maintain buffer behind.
     *
     * @remark
     * Some low-performant platforms (e.g. Netgem) have an optimisation in their Source Buffer implementation
     * that will automatically remove segments from behind the playhead. Where this is found to be the case this
     * flag *must* be set to `false`. When this flag is set to `false`, the value defined for the `bufferBehindGoal`
     * will ignored and `0` will be used.
     *
     * @see https://github.com/getndazn/mercury/blob/master/docs/netgem.md
     */

    public canMaintainBufferBehind = true;

    /**
     * The maximum allowed buffer ahead duration, when "hungry" buffering
     * is active.
     */

    public maxHungryBufferAheadDuration = 60 * 3;

    /**
     * The fraction of the buffer ahead goal that must be buffered to be
     * considered "healthy", meaning that parallel requests may be allowed.
     */

    public healthyBufferFraction = 11 / 12;

    /**
     * The fraction of the buffer ahead goal, below which all time-based EWMA
     * bandwidth estimates should be "re-seeded", based on the latest (more
     * volatile) throughput-based estimated.
     */

    public unhealthyBufferFraction = 8 / 12;

    /**
     * The fraction of the buffer ahead goal, below which the current variant
     * level should be reduced as an emergency counter measure to revive a low
     * buffer.
     */

    public criticalBufferFraction = 1 / 12;

    /**
     * The amount of downloaded content in seconds at which any content withheld
     * from the video and audio source buffers during seeking should be released
     * from withholding and appended, in order to ensure a healthy buffer before
     * the resumption of playing and prevent sporadic rebuffering.
     */

    public withholdOnSeekDuration = 2;

    /**
     * A boolean which toggles the feature for multiple Source Buffer appends of the same type during load.
     *
     * @configurable
     *
     * @remark
     * On some platforms (e.g. Netgem) Source Buffers of the same type do NOT support multiple append buffer operations
     * during load. In this case, Mercury can activate event-based Buffer Withholding on load until playback has
     * started. Otherwise, it can lead to unrecoverable stall.
     *
     * @test
     * Test whether a change is needed here by playing any content and observing Source Buffer operations in console.
     *
     * @see https://github.com/getndazn/mercury/blob/master/docs/netgem.md
     */

    public canAppendMultipleSegmentsDuringLoad = true;

    /**
     * A constant bits per second subtracted from all estimates to
     * ensure we are always playing a variant below the actual throughput
     * rate so that the buffer can be filled, and unnecessary adaptations
     * are reduced. Tuned for stability at low throughputs (vs using a
     * percentage multiplier that would be unnecessarily amplified at
     * high-throughput and negligible at low-throughput).
     */

    public safetyOffsetBits = 150000;

    /**
     * The maximum number of samples for mean-based rolling averages stored.
     */

    public maxRollingAverageSamples = 5;

    /**
     * Allow the player to consume all available buffer until memory limits are
     * hit, once hungry buffer criteria is met.
     */

    public allowHungryBuffer = false;

    /**
     * The minimum permitted duration between the live edge and the playhead for
     * live streams in the normal mode.
     *
     * A greater presentation delay allows for more parallelisation.
     * A lower presentation delay is enabled via AdaptivePresentationDelay feature.
     */

    public minPresentationDelay = 12;

    /**
     * Number of variant indices to drop when seeking during steady playback.
     */

    public seekingVariantDropCount = 2;

    /**
     * Number of variant indices to drop the next variant index by when switching
     * up with an unhealthy buffer. A greater drop count allows the unhealthy buffer
     * to recover quicker (while sacrificing quality).
     */

    public unhealthyBufferSwitchUpDropCount = 2;

    /**
     * The fraction of the buffer ahead goal that must be achieved before switching up
     * variants at startup.
     */

    public switchUpBufferFractionStartup = 3 / 12;

    /**
     * The fraction of the buffer ahead goal that must be achieved before switching up
     * variants during playback.
     */

    public switchUpBufferFractionMidstream = 8 / 12;

    /**
     * The number of dropped variant indices at which all in-flight requests
     * should be cancelled when switching down.
     */

    public abortRequestsOnSwitchDownThreshold = 3;

    /**
     * A duration between the buffer end time and the current time, below which a stall
     * will occur (and a BUFFERING state will be entered). The HTML5 video element can
     * stall before this reaches 0.
     */

    public stallThresholdSeconds = 0.4;

    /**
     * The maximum number of requests in-flight during healthy
     * conditions, even if additional redundancy is available.
     */

    public maxInflightRequestsCount = 3;

    /**
     * Defines an interval (in ms), at which the video element's `currentTime`
     * should be monitored for unexpected stalls (e.g. due to buffer underrun).
     *
     * NB: Polling is disabled if set to `-1`.
     */

    public stallDetectionPollIntervalMs = 1200;

    /**
     * The maximum number of samples held in the segment downloader's rolling
     * average of packet samples.
     */

    public maxDownloaderThroughputSamples = 10;

    /**
     * The frequency that incoming packets should be polled within the
     * segment downloader, in order to form a throughput estimate.
     */

    public downloaderPollingIntervalMs = 100;

    /**
     * The maximum amount of time to tolerate a BUFFERING state before re-seeding the
     * time-based bandwidth estimate from the throughput-based estimate.
     */

    public prolongedBufferingThresholdMs = 500;

    /**
     * The maximum rate of dropped frames per second to allow before indicating
     * that playback quality has fallen outside of the allowed threshold.
     */

    public droppedVideoFramesAccelerationThreshold = 2;

    /**
     * The maximum number of times the dropped video frame warning dispatches.
     */

    public droppedVideoFrameWarningDispatchLimit = 1;

    /**
     * An offset that defines the duration before a gap start.
     *
     * This is used to flag that a gap is approaching so that it can be jumped
     * earlier on those devices that would otherwise get stuck in a `waiting` state.
     *
     * @configurable
     * @title safeGapOffsetSeconds (multi-period only)
     *
     * @remark
     * On Tizen 3, trying to move over the gaps between ad and content periods nearly always causes the
     * UI thread to block for about 19-20 seconds. Only when the time update that triggered the gap
     * jumping (seeking) happens within 0.6s before the gap start is Mercury able to successfully jump the gap.
     * If too close to the gap, the next time update tick would drop the player into the gap, throwing a
     * "waiting" event, causing a hang when the seek was attempted. We mitigate this by setting `safeGapOffsetSeconds`
     * to perform `manifest-gap` jumps earlier. See [Gap Jumping](./gap-jumping.md) and [Tizen 3](./tizen-3.md).
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_multi_period_gaps.mpd`
     * and observing playback.
     */

    public safeGapOffsetSeconds = 0;

    /**
     * An offset that is applied to the recovery time.
     *
     * @configurable
     * @title safeGapRecoveryOffsetSeconds (multi-period only)
     *
     * @remark
     * On Tizen, there is heavy precision rounding of the current time (see
     * [Seeking - Reduced Time Precision](./seeking.md#reduced-time-precision)) that can make it possible
     * for the current time to fall backwards into a gap when set to the gap end. This will continue to
     * happen and the player will stall infinitely. We mitigate this by setting `safeGapRecoveryOffsetSeconds`
     * to perform gap jumps beyond the gap end, ensuring that if the time is rounded backwards it'll still
     * be on the correct side of the gap.
     *
     * @test
     * Test whether a change is needed here by playing `dash/number/stream_multi_period_gaps.mpd` and
     * observing playback. Given that time rounding is different per device and session, one run will
     * not be representative.
     */

    public safeGapRecoveryOffsetSeconds = 0;

    /**
     * The maximum gap duration to be tolerated between segments and period boundaries.
     */

    public smallGapThresholdSeconds = 0.5;

    /**
     * A flag dictating whether the audio decoder needs to be reset every time the video decoder is reset
     *
     * @configurable
     *
     * @remark
     * By default, both audio and video decoders are reset on variant change - this is despite Mercury only
     * supporting a single audio variant. On Movistar, resetting both audio and video decoders unnecessarily
     * (in the case of Mercury, resetting the audio decoder whilst changing video variant) can cause the video
     *  to stall momentarily - see [Movistar - Duplicate Init Data Appends](./movistar.md#duplicate-init-data-appends).
     * On Tizen, both decoders must be reset on variant change to prevent video corruption
     * - hence this value is configurable.
     *
     * @test
     * Test whether a change is needed here by changing variants frequently and observing playback.
     */
    public resetAudioDecoderOnVideoProfileChange = true;

    /**
     * A boolean which indicates if the time based and throughput based bandwidth estimates should be reset on each
     * load request.
     *
     * @configurable
     *
     * @remark
     * This configuration flag is provided for use in a Client Multiview application, where multiple player instances
     * are created and maybe executing at the same time, and you want to avoid using the existing bandwidth calculations
     * on the next load as they might be inaccurate.
     *
     * This is not the same as `canUseLocalStorage` in `ConfigBehaviour` which controls whether the bandwidth estimate
     * can be stored in local storage.
     *
     * @test
     * No test is required.
     */

    public canUseRetainedBandwidthEstimate = true;
}

export {ConfigAbr};
