import type {Manifest} from '@Shared/Models/Manifest';
import type {TDeepMutable} from '@Shared/Types/TMutable';

import type {Metrics} from '../Models/Metrics';

const populateMetricsLinksFromManifest = (manifest: Manifest, value: TDeepMutable<Metrics>): Metrics => {
    value.timing.isDynamic = manifest.isDynamic;
    value.timing.hasDynamicManifestEnded = manifest.hasDynamicManifestEnded;
    value.timing.seekRangeStartTimeSeconds = manifest.seekRangeStartTimeSeconds;
    value.timing.seekRangeEndTimeSeconds = manifest.seekRangeEndTimeSeconds;
    value.timing.suggestedPresentationDelaySeconds = manifest.suggestedPresentationDelaySeconds;
    value.network.normalSegmentDurationSeconds = manifest.normalSegmentDurationSeconds;
    value.network.bitrates = manifest.bitrates;

    return value;
};

export {populateMetricsLinksFromManifest};
