import {PlayheadStatus} from '../Constants/PlayheadStatus';
import type {IRange} from '../Interfaces/IRange';

interface IGetPlayheadStatusAndRangeIndexOptions {
    ranges: IRange[];
    currentTimeSeconds: number;
    smallGapThresholdSeconds: number;
    stallThresholdSeconds: number;
    safeGapOffsetSeconds: number;
}

/**
 * @returns A tuple of the playhead status for the stream, and the
 * index of the enveloping range, or next range (if in gap)
 */

const getPlayheadStatusAndRangeIndex = ({
    ranges,
    currentTimeSeconds,
    smallGapThresholdSeconds,
    stallThresholdSeconds,
    safeGapOffsetSeconds,
}: IGetPlayheadStatusAndRangeIndexOptions): [PlayheadStatus, number] => {
    for (let i = 0; i < ranges.length; i++) {
        const range = ranges[i];
        const nextRange: IRange | null = ranges[i + 1] ?? null;
        const nextRangeStart = nextRange?.startSeconds ?? Infinity;
        const effectiveRangeEndSeconds = range.endSeconds - stallThresholdSeconds;

        if (currentTimeSeconds < range.startSeconds || (currentTimeSeconds > effectiveRangeEndSeconds && !nextRange)) {
            // Before first range, or after range and no more ranges, outside buffer

            break;
        } else if (
            nextRange &&
            safeGapOffsetSeconds > 0 &&
            currentTimeSeconds >= range.endSeconds - safeGapOffsetSeconds &&
            currentTimeSeconds <= range.endSeconds
        ) {
            // Early gap detection

            return [PlayheadStatus.INSIDE_GAP, i + 1];
        } else if (currentTimeSeconds >= range.startSeconds && currentTimeSeconds < effectiveRangeEndSeconds) {
            // Safely within the buffer

            return [PlayheadStatus.INSIDE_BUFFER, i];
        } else if (currentTimeSeconds > effectiveRangeEndSeconds && currentTimeSeconds >= nextRangeStart) {
            // Go to next range

            continue;
        }

        // Else, in gap

        const gapDurationSeconds = nextRangeStart - range.endSeconds;

        // Small gap

        if (gapDurationSeconds <= smallGapThresholdSeconds) return [PlayheadStatus.INSIDE_GAP, i + 1];

        // Large gap, treat as outside buffer

        break;
    }

    return [PlayheadStatus.OUTSIDE_BUFFER, -1];
};

export type {IGetPlayheadStatusAndRangeIndexOptions};
export {getPlayheadStatusAndRangeIndex};
