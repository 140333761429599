// To maintain backwards-compatibility, does not use the new URL() API
export type TUrl = Pick<
    HTMLAnchorElement,
    'protocol' | 'hostname' | 'port' | 'host' | 'origin' | 'pathname' | 'search' | 'hash' | 'href'
>;

const parseUrl = (url: string): TUrl | null => {
    if (!url) {
        return null;
    }

    const link = document.createElement('a');

    link.href = url;

    const {protocol, hostname, port, host, origin, pathname, search, hash, href} = link;

    if (origin === '') {
        return null;
    }

    return {
        protocol,
        hostname,
        port,
        host,
        origin,
        pathname,
        search,
        hash,
        href,
    };
};

export {parseUrl};
