import type {IVariantChangeFilter} from '@Features/PlayerHealth/Interfaces/IVariantChangeFilter';

class ConfigPlayerHealth {
    /**
     * An array of IVariantChangeFilter, dictating how many variant changes must
     * be seen in what period of time in order to dump mercury logs and send
     * a warning to New Relic.
     */
    public variantChangeReportFilters: IVariantChangeFilter[] = [
        {windowMs: 100, limit: 3, take: 3},
        {windowMs: 30000, limit: 20, take: 3},
    ];
}

export {ConfigPlayerHealth};
