import {arrayIncludes} from '@dazn/peng-html5-tools-utils';
import type {Subscription} from 'rxjs';
import {filter, Subject} from 'rxjs';

import {hasElements} from '@Shared/Util/hasElements';

import {DebugVerbosityLevel} from './Constants/DebugVerbosityLevel';
import type {MessageNamespace} from './Constants/MessageNamespace';
import {MessageType} from './Constants/MessageType';
import type {DebugView} from './DebugView/DebugView';
import type {ILogger} from './Interfaces/ILogger';
import type {IMessagePayload} from './Interfaces/IMessagePayload';
import type {TMessage} from './Types/TMessage';
import type {TMessageId} from './Types/TMessageId';

const DEFAULT_MESSAGE_HISTORY_LENGTH = 200;

/**
 * A singleton logger whose purpose is to store a rich history of all internal
 * events in the most terse format possible, so that all messages can be quickly
 * transported over the network in case of a fatal error (while in production).
 *
 * Alternatively, while in a development environment, messages can be simply streamed
 * to the console in real time - by a code-split logger "DebugView" that can be stripped from
 * the production build, or async loaded on demand for debugging when needed.
 */

interface ILogParams {
    type: MessageType;
    namespace: MessageNamespace;
    id: number;
    shouldDispatchMessage: boolean;
    args: Array<string | number | Event>;
}

class Logger implements ILogger {
    public debugView: DebugView | null = null;
    public message$ = new Subject<IMessagePayload>();
    public maxMessageHistoryLength = DEFAULT_MESSAGE_HISTORY_LENGTH;
    public passThrough: boolean = false;
    public messageFilterLevel = MessageType.LOG;

    private messages: TMessage[] = [];
    private subscriptions: Subscription[] = [];

    public async debug({
        enable,
        messageFilterLevel,
        passThrough,
        verbosityLevel,
        localStorageStore,
    }: Parameters<ILogger['debug']>[0]): Promise<void> {
        this.messageFilterLevel = messageFilterLevel;
        this.passThrough = passThrough;

        if (!enable || this.passThrough) {
            if (!this.debugView) return;

            this.destroy();

            return;
        }

        // Already subscribed, do not re-subscribe (e.g. after HMR reload)

        if (hasElements(this.subscriptions)) return;

        const {DebugView: DebugViewConstructor} = await import(
            /* webpackChunkName: "debug-view" */ './DebugView/DebugView'
        );

        this.debugView = new DebugViewConstructor(localStorageStore);

        const filteredMessages$ = this.message$.pipe(filter(Logger.filterMessage.bind(null, verbosityLevel)));

        this.subscriptions.push(
            filteredMessages$.subscribe(({message}) => {
                this.debugView!.renderMessage(message);
            }),
        );

        if (hasElements(this.messages)) {
            // Push all logs buffered before debug view was attached to the debug view

            this.messages.forEach(log =>
                this.message$.next({
                    message: log,
                    shouldDispatchMessage: false,
                }),
            );
        }
    }

    public getMessageHistory(): TMessage[] {
        if (this.passThrough)
            throw new Error(
                'Cannot get message history when pass through mode enabled. ' +
                    'Message storage is delegated to the consumer.',
            );

        return this.messages.slice();
    }

    public info(namespace: MessageNamespace, id: TMessageId, ...args: any[]): void {
        if (this.messageFilterLevel > MessageType.INFO) return;

        this.pushLog({
            type: MessageType.INFO,
            namespace,
            id,
            shouldDispatchMessage: false,
            args,
        });
    }

    public log(namespace: MessageNamespace, id: TMessageId, ...args: any[]): void {
        if (this.messageFilterLevel > MessageType.LOG) return;

        this.pushLog({
            type: MessageType.LOG,
            namespace,
            id,
            shouldDispatchMessage: false,
            args,
        });
    }

    public logAndDispatchMessage(namespace: MessageNamespace, id: TMessageId, ...args: any[]): void {
        this.pushLog({
            type: MessageType.LOG,
            namespace,
            id,
            shouldDispatchMessage: true,
            args,
        });
    }

    public warn(namespace: MessageNamespace, id: TMessageId, ...args: any[]): void {
        if (this.messageFilterLevel > MessageType.WARNING) return;

        this.pushLog({
            type: MessageType.WARNING,
            namespace,
            id,
            shouldDispatchMessage: false,
            args,
        });
    }

    public warnAndDispatchMessage(namespace: MessageNamespace, id: TMessageId, ...args: any[]): void {
        this.pushLog({
            type: MessageType.WARNING,
            namespace,
            id,
            shouldDispatchMessage: true,
            args,
        });
    }

    public error(namespace: MessageNamespace, id: TMessageId, ...args: any[]): void {
        this.pushLog({
            type: MessageType.ERROR,
            namespace,
            id,
            shouldDispatchMessage: false,
            args,
        });
    }

    public destroy(): void {
        while (hasElements(this.subscriptions)) this.subscriptions.pop()!.unsubscribe();

        this.debugView = null;
    }

    private pushLog({type, namespace, id, shouldDispatchMessage, args}: ILogParams): void {
        const log: TMessage = [type, Date.now(), namespace, id, args];

        if (!this.passThrough) {
            this.messages.push(log);

            if (this.messages.length > this.maxMessageHistoryLength) this.messages.shift();
        }

        this.message$.next({message: log, shouldDispatchMessage});
    }

    private static filterMessage(verbosityLevel: DebugVerbosityLevel, {message}: IMessagePayload): boolean {
        const [messageType] = message;

        switch (verbosityLevel) {
            case DebugVerbosityLevel.ERROR:
                if (arrayIncludes([MessageType.INFO, MessageType.LOG, MessageType.WARNING], messageType)) return false;

                break;
            case DebugVerbosityLevel.WARN:
                if (arrayIncludes([MessageType.INFO, MessageType.LOG], messageType)) return false;

                break;
            case DebugVerbosityLevel.LOG:
                if (arrayIncludes([MessageType.INFO], messageType)) return false;

                break;
            case DebugVerbosityLevel.INFO:
                return true;
        }

        return true;
    }
}

const logger: ILogger = new Logger();

export {logger, Logger};
