import type {ISecurityToken} from '@Shared/Interfaces/ISecurityToken';

const isMatchingHostname = (token: ISecurityToken | null, hostname: string | undefined) => {
    if (token) {
        const originHostname = token.originHostname;

        if (!originHostname) return true;

        if (originHostname === hostname) return true;
    }

    return false;
};

export {isMatchingHostname};
