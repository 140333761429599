import type {TDeepMutable} from '@Shared/Types/TMutable';
import type {Config} from '@State/Stores/Config/Models/Config';

import type {Metrics} from '../Models/Metrics';

const populateMetricsLinksFromConfig = (config: Config, value: TDeepMutable<Metrics>): Metrics => {
    value.playback.configBehaviourAutoplay = config.behaviour.autoplay;
    value.playback.configBehaviourAllowZeroPlaybackRate = config.behaviour.allowZeroPlaybackRate;
    value.network.configAbr = config.abr;
    value.timing.configAbr = config.abr;
    value.timing.AUDIO.configAbr = config.abr;
    value.timing.VIDEO.configAbr = config.abr;
    value.timing.TEXT.configAbr = config.abr;
    value.timing.configBehaviourSafeSeekEndOffsetSeconds = config.behaviour.safeSeekEndOffsetSeconds;

    return value;
};

export {populateMetricsLinksFromConfig};
